<template>
  <section class="section">
    <div>
      <h4 class="title has-text-centered is-4">Task Form</h4>

      <form @submit="handleSubmit">
        <div class="box">
          <h5 class="title is-5">General</h5>
          <div class="columns">
            <div class="column is-3">
              <b-field label="ORDER" custom-class="is-small">
                <b-input v-model="formData.order" size="is-small"></b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Reward XP" custom-class="is-small">
                <b-input v-model="formData.reward_xp" size="is-small"></b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Reward Chest" custom-class="is-small">
                <b-input v-model="formData.reward_chest" size="is-small"></b-input>
              </b-field>
            </div>
          </div>

          <h5 class="title is-5">Required Recipes</h5>

          <b-button type="is-info" icon-left="plus" size="is-small" @click="addRecipe()">Add recipe</b-button>

          <div v-for="recipe in formData.recipes" :key="recipe.id" class="mt-4">
            <b-field label="Required item" custom-class="is-small">
              <b-autocomplete
                v-model="recipe.item_id"
                size="is-small"
                ref="autocomplete"
                :data="filteredItemsArray(recipe.item_id)"
                :select-on-click-outside="true"
                :custom-formatter="(item) => `Energy=${item.energy_required} | ${item.name} | ${item.id}`"
                placeholder="Required item"
                field="id"
                keep-first
                @select="option => selected = option">
                <template #empty>No results for {{ recipe.item_id }}</template>
              </b-autocomplete>
            </b-field>
          </div>

          <div style="margin-top: 10px;">
            <strong>ENERGY: {{ calculatedEnergy }}</strong>
          </div>

        </div>

        <b-button type="is-success" native-type="submit">{{ task ? 'Update' : 'Create' }}</b-button>

      </form>
    </div>

    <h4 class="title has-text-centered is-4">Tasks</h4>
    <b-table
      :data="tasks.data"
      :striped="true"
      :hoverable="true"
      :loading="loadingTasks"
      class="mt-4 clickable-table user-table"
      @click="editTask"
    >
      <b-table-column field="id" label="ORDER" v-slot="props" centered>
        <strong>{{ props.row.order }}</strong>
      </b-table-column>

      <b-table-column field="recipes" label="Recipes" v-slot="props">
        <div style="text-align: center;">
          <table>
            <tr>
              <td v-for="recipe in props.row.recipes" :key="recipe.id">
                <img :src="itemImageSource(recipe.item_id)" class="furniture-image" />
                <br />
                {{ recipe.item_id }}
              </td>
            </tr>
          </table>
        </div>
      </b-table-column>

      <b-table-column field="reward_xp" label="Reward XP" v-slot="props">
        <p><strong>{{ props.row.reward_xp }}</strong></p>
      </b-table-column>

      <b-table-column field="reward_chest" label="Reward Chest" v-slot="props">
        <p>{{ props.row.reward_chest }}</p>
      </b-table-column>
    </b-table>

  </section>
</template>

<script>
import { mapState } from 'vuex';
import { getCraftingImageUrl } from '../../services/encyclopedia';
import { Crafting } from '../../services/api';

export default {
  name: 'Crafting',
  computed: {
    ...mapState('crafting', ['loadingItems', 'items']),
    calculatedEnergy() {
      let energy = 0;

      this.formData.recipes.forEach((recipe) => {
        if (recipe.item_id) {
          const found = this.items.find((item) => item.id === this.getItemIdFromTask(recipe.item_id));

          if (found) {
            energy += found.energy_required;
          }
        }
      });

      return energy;
    },
  },
  data() {
    return {
      task: null,
      errors: [],
      loadingForm: false,
      loadingTasks: false,
      sent: false,
      tasks: [],
      formData: {
        order: null,
        reward_xp: null,
        reward_chest: null,
        recipes: [],
      },
    };
  },
  mounted() {
    this.loadItems();
    this.loadTasks();
  },
  methods: {
    getItemIdFromTask(task) {
      const parts = task.split(' | ');

      if (parts[2]) {
        return parts[2];
      }

      return task;
    },
    filteredItemsArray(item) {
      return typeof item !== 'string' || !this.items
        ? []
        : this.items.filter((option) => option.id.toString().toLowerCase().indexOf(item.toLowerCase()) >= 0
          || option.name.toString().toLowerCase().indexOf(item.toLowerCase()) >= 0);
    },
    loadTasks() {
      this.loadingTasks = true;
      Crafting.getTasks()
        .then((data) => {
          this.tasks = data;
        })
        .finally(() => {
          this.loadingTasks = false;
        });
    },
    loadItems() {
      this.$store.dispatch('crafting/loadItems');
    },
    addRecipe() {
      this.formData.recipes.push({
        item_id: null,
      });
    },
    editTask(row) {
      this.task = row;
      this.formData = {
        ...this.task,
      };
    },
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      this.formData.recipes = this.formData.recipes.map((recipe) => ({
        ...recipe,
        item_id: this.getItemIdFromTask(recipe.item_id),
      }));
      const action = this.task ? Crafting.updateTask(this.task.id, this.formData) : Crafting.addTask(this.formData);

      action
        .then(() => {
          this.sent = true;

          this.task = null;
          this.formData = {
            order: null,
            reward_xp: null,
            reward_chest: null,
            recipes: [],
          };

          this.loadTasks();
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    itemImageSource(id) {
      return getCraftingImageUrl('items', id);
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.furniture-image {
  max-height: 40px;
  max-width: 40px;
}
</style>
